import React, { Component } from "react";
import { Link } from "react-router-dom";

export class Top extends Component {
    render() {
        return (
            <div>
                <main>
                    <div className="pc-fv">
                        <div className="pc-cd-fixed-bg pc-cd-bg-1">
                            <div className="area">
                                <img
                                    src="img/top_card.png"
                                    className="pc-card"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="sp-fv">
                        <div
                            className="sp-cd-fixed-bg sp-cd-bg-1"
                            style={{ width: "100%" }}
                        >
                            <div className="area">
                                <img
                                    src="img/top_card.png"
                                    className="sp-card"
                                />
                            </div>
                        </div>
                    </div>
                </main>
                <div className="shop">
                    <div className="top_coment">
                        <h2>New</h2>
                    </div>
                    <div className="top">
                        <div className="column">
                            <Link to="/Product">
                                <img
                                    className="top_img"
                                    src="img/product_sample.png"
                                />
                                Earrings(Stone_GR)
                                <br />
                                <br />
                                <span className="top_price">¥500</span>
                            </Link>
                        </div>
                        <div className="column">
                            <Link to="/Product">
                                <img
                                    className="top_img"
                                    src="img/product_sample.png"
                                />
                                Earrings(Stone_GR)
                                <br />
                                <br />
                                <span className="top_price">¥1,500</span>
                            </Link>
                        </div>
                        <div className="column">
                            <Link to="/Product">
                                <img
                                    className="top_img"
                                    src="img/product_sample.png"
                                />
                                Earrings(Stone_GR)
                                <br />
                                <br />
                                <span className="top_price">¥500</span>
                            </Link>
                        </div>
                    </div>
                    <div className="sp_top">
                        <div className="column">
                            <Link to="/Product">
                                <img
                                    className="top_img"
                                    src="img/product_sample.png"
                                />
                                Earrings(Stone_GR)
                                <br />
                                <span className="top_price">¥500</span>
                            </Link>
                        </div>
                        <div className="column">
                            <Link to="/Product">
                                <img
                                    className="top_img"
                                    src="img/product_sample.png"
                                />
                                Earrings(Stone_GR)
                                <br />
                                <span className="top_price">¥500</span>
                            </Link>
                        </div>
                    </div>
                    <Link to="/AllItems" className="btn-more pushdown">
                        <span>more</span>
                    </Link>
                </div>
                <div id="concept" className="concept_class">
                    <div className="concept_coment">
                        <h2>Concept</h2>
                    </div>
                    <a className="concept_text">
                        一つ一つ丁寧に手作りしています。
                        ハワイのウミガメや貝殻などをモチーフにし、どこか懐かしい味のあるアクセサリーとなるよう仕上げております。
                        ハワイの雰囲気を感じたい方にぜひ手にとっていただければ幸いです。
                    </a>
                    <ul className="concept_icon">
                        <li>
                            <img src="img/concept_icon.png" />
                            <ul className="concept_icon_coment">
                                <li>
                                    樹脂フック
                                    <br />
                                    使用可能
                                </li>
                            </ul>
                        </li>
                        <li>
                            <img src="img/concept_icon.png" />
                            <ul className="concept_icon_coment">
                                <li>天然素材</li>
                            </ul>
                        </li>
                        <li>
                            <img src="img/concept_icon.png" />
                            <ul className="concept_icon_coment">
                                <li>オーガニック</li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <div id="contact" className="contact_class">
                    <div className="contact_coment">
                        <h2>Contact us</h2>
                    </div>
                    <a className="contact_text">
                        何かご不明な点等ございましたらこちらまでお問い合わせください。
                    </a>
                    <textarea
                        id="story"
                        className="contact_textarea"
                        rows="10"
                        cols="33"
                    ></textarea>
                    <Link to="/AllItems" className="btn-more pushdown">
                        <span>Submit</span>
                    </Link>
                </div>

                <div id="access" className="access_class">
                    <div className="concept_coment">
                        <h2>Access</h2>
                    </div>
                    <div className="access_left">
                        <img src="img/access.jpg"></img>
                    </div>
                    <div className="access_right">
                        <span className="access_right_title">| Address |</span>
                        <span>〒999-9999</span>
                        <span>東京都〇〇区▲▲9-99-999</span>
                        <span>東京都〇〇区▲▲9-99-999</span>
                        <span className="access_right_title">| TEL |</span>
                        <span>9999-99-9999</span>
                    </div>
                </div>
            </div>
        );
    }
}
