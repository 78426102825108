import React, { Component } from "react";

export class Cart extends Component {
  render() {
    return (
      <div>
        <main>
          <div className="shop">
            <div className="top_coment">
              <h2>カート</h2>
            </div>
            <div className="cart_contents">
              <div className="column">
                <a href="01/index.html">
                  <img className="top_img" src="img/product_sample.png" />
                </a>
              </div>
              <a href="01/index.html"></a>
              <div className="column">
                <a href="01/index.html"></a>
                <a>
                  Earrings(Stone_GR)
                  <br />
                  ¥1,500
                </a>
                <div className="container">
                  <div className="field">
                    <button className="button" id="down">
                      －
                    </button>
                    <input
                      type="text"
                      defaultValue={0}
                      className="inputtext"
                      id="textbox"
                    />
                    <button className="button" id="up">
                      ＋
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="shop">
            <div className="cart_contents">
              <div className="column">
                <a href="01/index.html">
                  <img className="top_img" src="img/product_sample.png" />
                </a>
              </div>
              <a href="01/index.html"></a>
              <div className="column">
                <a href="01/index.html"></a>
                <a>
                  Earrings(Stone_GR)
                  <br />
                  ¥1,500
                </a>
                <div className="container">
                  <div className="field">
                    <button className="button" id="down">
                      －
                    </button>
                    <input
                      type="text"
                      defaultValue={0}
                      className="inputtext"
                      id="textbox"
                    />
                    <button className="button" id="up">
                      ＋
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="cart_btn">
            <a href="all.html" className="btn-more pushdown">
              <span>購入する</span>
            </a>
          </div>
        </main>
      </div>
    );
  }
}
