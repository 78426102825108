import React, { Component } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Header } from "./component-main/Header";
import { Top } from "./component-main/Top";
import { Product } from "./component-main/Product";
import { AllItems } from "./component-main/AllItems";
import { Bangle } from "./accessory/Bangle";
import { Earrings } from "./accessory/Earrings";
import { Hairband } from "./accessory/Hairband";
import { Necklace } from "./accessory/Necklace";
import { Ring } from "./accessory/Ring";
import { Cart } from "./component-main/Cart";
import { Footer } from "./component-main/Footer";

class App extends Component {
	render() {
		return (
			<BrowserRouter>
				<Header />
				<Routes>
					<Route index element={<Top />} />
					<Route path="Product" element={<Product />} />
					<Route path="AllItems" element={<AllItems />} />
					<Route path="Bangle" element={<Bangle />} />
					<Route path="Earrings" element={<Earrings />} />
					<Route path="Hairband" element={<Hairband />} />
					<Route path="Necklace" element={<Necklace />} />
					<Route path="Ring" element={<Ring />} />
					<Route path="Cart" element={<Cart />} />
				</Routes>
				<Footer />
			</BrowserRouter>
		);
	}
}

export default App;
