import React, { Component } from "react";
import { Link } from "react-router-dom";

export class Hairband extends Component {
    render() {
        return (
            <>
                <div>
                    <div className="shop">
                        <div className="top_coment">
                            <h2>Hairband</h2>
                        </div>
                        <div className="top">
                            <div className="column">
                                <div className="icon_new">New</div>
                                <Link to="/Product">
                                    <img
                                        className="top_img"
                                        src="img/product_sample.png"
                                    />
                                    Earrings(Stone_GR)
                                    <br />
                                    <br />
                                    <span className="top_price">¥500</span>
                                </Link>
                            </div>
                            <div className="column">
                                <Link to="/Product">
                                    <img
                                        className="top_img"
                                        src="img/product_sample.png"
                                    />
                                    Earrings(Stone_GR)
                                    <br />
                                    <br />
                                    <span className="top_price">¥1,500</span>
                                </Link>
                            </div>
                            <div className="column">
                                <div className="icon_popular">人気</div>
                                <Link to="/Product">
                                    <img
                                        className="top_img"
                                        src="img/product_sample.png"
                                    />
                                    Earrings(Stone_GR)
                                    <br />
                                    <br />
                                    <span className="top_price">¥500</span>
                                </Link>
                            </div>
                        </div>
                        <div className="sp_top">
                            <div className="column">
                                <div className="icon_new_sp">New</div>
                                <Link to="/Product">
                                    <img
                                        className="top_img"
                                        src="img/product_sample.png"
                                    />
                                    Earrings(Stone_GR)
                                    <br />
                                    <span className="top_price">¥500</span>
                                </Link>
                            </div>
                            <div className="column">
                                <Link to="/Product">
                                    <img
                                        className="top_img"
                                        src="img/product_sample.png"
                                    />
                                    Earrings(Stone_GR)
                                    <br />
                                    <span className="top_price">¥500</span>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>

                <i className="fa-sharp fa-solid fa-circle">
                    <i className="fa-sharp fa-solid fa-circle"></i>
                </i>
            </>
        );
    }
}
