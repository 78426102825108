import React, { Component } from "react";
import { Link } from "react-router-dom";
import "../css/style.css";
import "../css/destyle.css";
import { HashLink } from "react-router-hash-link";

export class Header extends Component {
    render() {
        return (
            <div>
                <link rel="manifest" href="%PUBLIC_URL%/manifest.json" />
                <link rel="stylesheet" href="style.css" />
                <link rel="stylesheet" href="destyle.css" />
                <meta name="viewport" content="width=device-width" />
                <link rel="preconnect" href="https://fonts.gstatic.com" />
                <link rel="preconnect" href="https://fonts.googleapis.com" />
                <link rel="preconnect" href="https://fonts.gstatic.com" />
                <link rel="preconnect" href="https://fonts.googleapis.com" />
                <link
                    rel="preconnect"
                    href="https://fonts.gstatic.com"
                />
                <link
                    href="https://fonts.googleapis.com/css2?family=Courgette&family=Lexend+Deca:wght@100;200;300;400;500;600;700;800;900&family=Noto+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Noto+Serif+JP:wght@200;300;400;500;600;700;900&display=swap"
                    rel="stylesheet"
                />
                <link
                    href="https://fonts.googleapis.com/earlyaccess/hannari.css"
                    rel="stylesheet"
                />
                <header>
                    <ul className="sp_header">
                        <div className="header-logo-menu">
                            <div id="nav-drawer">
                                <input
                                    id="nav-input"
                                    type="checkbox"
                                    className="nav-unshown"
                                />
                                <label id="nav-open" htmlFor="nav-input">
                                    <span />
                                </label>
                                <label
                                    className="nav-unshown"
                                    id="nav-close"
                                    htmlFor="nav-input"
                                />
                                <div className="nav-content">
                                    <ul>
                                        <li className="li-01">
                                            <Link to="/">Top</Link>
                                        </li>
                                        <li className="li-02">
                                            <HashLink to="/#concept">
                                                Concept
                                            </HashLink>
                                        </li>
                                        <li className="li-03">
                                            <Link to="/AllItems">Items</Link>
                                            <Link to="/Earrings">
                                                <span className="nav_shop_menu">
                                                    Earrings
                                                </span>
                                            </Link>
                                            <Link to="/Ring">
                                                <span className="nav_shop_menu">
                                                    Ring
                                                </span>
                                            </Link>
                                            <Link to="/Necklace">
                                                <span className="nav_shop_menu">
                                                    Necklace
                                                </span>
                                            </Link>
                                            <Link to="/Hairband">
                                                <span className="nav_shop_menu">
                                                    Hairband
                                                </span>
                                            </Link>
                                            <Link to="/Bangle">
                                                <span className="nav_shop_menu">
                                                    Bangle
                                                </span>
                                            </Link>
                                        </li>
                                        <li className="li-04">
                                            <HashLink to="/#contact">
                                                Contact
                                            </HashLink>
                                        </li>
                                        <li className="li-05">
                                            <HashLink to="/#access">
                                                Access
                                            </HashLink>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </ul>
                    <Link to="/">
                        <img className="pc_logo" src="img/logo.png" />
                    </Link>
                    <ul className="pc_header">
                        <li>
                            <HashLink to="/#concept" className="btn2">
                                Concept
                            </HashLink>
                        </li>
                        <li>
                            <Link to="/AllItems" className="btn3">
                                Items
                            </Link>
                            <ul className="shop_menu">
                                <Link to="/Earrings">
                                    <li className="shop_menu_contents">
                                        Earrings
                                    </li>
                                </Link>
                                <Link to="/Ring">
                                    <li className="shop_menu_contents">Ring</li>
                                </Link>
                                <Link to="/Necklace">
                                    <li className="shop_menu_contents">
                                        Necklace
                                    </li>
                                </Link>
                                <Link to="/Hairband">
                                    <li className="shop_menu_contents">
                                        Hairband
                                    </li>
                                </Link>
                                <Link to="/Bangle">
                                    <li className="shop_menu_contents">
                                        Bangle
                                    </li>
                                </Link>
                            </ul>
                        </li>
                        <li>
                            <HashLink to="/#contact" className="btn4">
                                Contact
                            </HashLink>
                        </li>
                        <li>
                            <HashLink to="/#access" className="btn5">
                                Access
                            </HashLink>
                        </li>
                        <Link to="/Cart">
                            <li className="header_icon">
                                <img src="img/cart.png" />
                                <i className="fa-sharp fa-solid fa-circle" />
                            </li>
                        </Link>
                    </ul>
                    <div className="sp_logo">
                        <Link to="/">
                            <img className="image-sp-logo" src="img/logo.png" />
                        </Link>
                    </div>
                    <div className="sp_header_icon">
                        <Link to="/Cart">
                            <li className="header_icon">
                                <img
                                    src="img/cart.png"
                                    className="sp_header_icon_img"
                                />
                                <i className="fa-sharp fa-solid fa-circle" />
                            </li>
                        </Link>
                    </div>
                </header>
            </div>
        );
    }
}
