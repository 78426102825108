import React, { Component } from "react";

export class Product extends Component {
    render() {
        return (
            <div>
                <div className="product_page">
                    <div className="product_column">
                        <a href="index.html">
                            <img
                                className="product_img"
                                src="img/product_sample.png"
                                alt="product"
                            />
                        </a>
                    </div>
                    <div className="product_column">
                        <div className="product_text">
                            <a>Earrings(Stone_GR)</a>
                            <br />
                            <br />
                            <a>¥500(税込)</a>
                        </div>
                        <div className="product_color">
                            <div className="color_green"></div>
                            <div className="color_pink"></div>
                            <div className="color_white"></div>
                        </div>

                        <div className="product_accordion">
                            <div className="product_option">
                                <input
                                    type="checkbox"
                                    id="product_toggle1"
                                    className="product_toggle"
                                ></input>
                                <label
                                    className="product_title"
                                    for="product_toggle1"
                                >
                                    商品説明
                                </label>
                                <div className="product_content">
                                    <p>テキストテキストテキスト</p>
                                </div>
                            </div>

                            <div className="product_option">
                                <input
                                    type="checkbox"
                                    id="product_toggle2"
                                    className="product_toggle"
                                ></input>
                                <label
                                    className="product_title"
                                    for="product_toggle2"
                                >
                                    配送について
                                </label>
                                <div className="product_content">
                                    <p>テキストテキストテキスト</p>
                                </div>
                            </div>

                            <div className="product_option">
                                <input
                                    type="checkbox"
                                    id="product_toggle3"
                                    className="product_toggle"
                                ></input>
                                <label
                                    className="product_title"
                                    for="product_toggle3"
                                >
                                    返品について
                                </label>
                                <div className="product_content">
                                    <p>テキストテキストテキスト</p>
                                </div>
                            </div>
                        </div>

                        <a href="all.html" className="pc_btn_product pushdown">
                            <span>カートに入れる</span>
                        </a>
                    </div>
                </div>
                <a href="all.html" className="sp_btn_product pushdown">
                    <span>カートに入れる</span>
                </a>
            </div>
        );
    }
}
