import React, { Component } from "react";
import { Link } from "react-router-dom";

export class Footer extends Component {
    render() {
        return (
            <footer>
                <div className="footer_menu">
                    <div className="footer_left">Meria</div>
                    <div className="footer_right">
                        <Link to="/">
                            <span>| Concept</span>
                        </Link>
                        <Link to="/">
                            <span>| Items</span>
                        </Link>
                        <Link to="/">
                            <span>| Contant</span>
                        </Link>
                        <Link to="/">
                            <span>| Access</span>
                        </Link>
                    </div>
                </div>
                <div className="footer_copyright">
                    COPYRIGHT © 2022 K.PLATINUM ALL RIGHTS RESERVED.
                </div>
            </footer>
        );
    }
}
